.device-monitor {
  height: calc(100vh - 64px);
}

.device-monitor .map-wrapper {
  height: calc(100vh - 64px);
  width: calc(100vw - 400);
}

.right-panel {
  height: 100%;
}

.list-wrapper {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 180px);
  width: 100%;
  padding: 10px;
}

.pd-block {
  position: relative;
}

.pd-block.active {
  font-size: 18px;
  z-index: 1000;
  font-weight: bold;
  padding: 5px;
}

.message-item {
  cursor: pointer;
}

.message-item.active {
  font-weight: bold;
}

.dnata-monitor .map-wrapper {
  height: calc(100vh - 64px);
  width: calc(100vw - 400);
}

.tractor-view {
  height: calc(100vh - 450px);
  overflow-y: scroll;
}

.detect-location {
  left: -5px;
  top: -5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #1890ff;
  z-index: 1001;
  position: relative;
}

.detect-location.active {
  background-color: #52c41a;
}

.detect-location.active.highlight {
  height: 16px;
  width: 16px;
  left: -8px;
  top: -8px;
  background-color: #eb2f96;
}
